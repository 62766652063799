var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',[_c('section',{staticClass:"w-100"},[_c('section',[_c('h5',{staticClass:"mb-0"},[_vm._v("Selecione até 5 seguros para adicionar")]),_c('span',[_vm._v(_vm._s(_vm.selecteds.length)+" selecionados")])]),_c('div',{staticClass:"input-search w-100 p-0 my-2"},[_c('b-form-input',{staticClass:"mb-2",attrs:{"placeholder":"Digite para pesquisar"},on:{"input":_vm.handleSearch},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}),_c('b-button',{staticClass:"px-2 d-flex align-items-center absolute icon-action",attrs:{"variant":"light"}},[_c('b-icon',{attrs:{"icon":"search"}})],1)],1)]),_c('cards-list',{staticClass:"content-security-modal",attrs:{"grid":"3","responsive":"1","gap":"12"}},_vm._l((_vm.items),function(insurance,index){return _c('b-card',{key:index,staticClass:"pointer card-security p-3",class:[
          _vm.selecteds.includes(insurance) && 'selected',
          _vm.selecteds.length === 5 &&
            !_vm.selecteds.includes(insurance) &&
            'disabled',
        ],attrs:{"no-body":""},on:{"click":function($event){return _vm.handleSelect(insurance)}}},[_c('span',{staticClass:"ball-selection",class:_vm.selecteds.includes(insurance) && 'selected'}),_c('div',{staticClass:"mr-md-4"},[(
              insurance && insurance.insurance && insurance.insurance_company
            )?_c('span',{staticClass:"d-block title"},[_vm._v(" "+_vm._s(insurance.insurance.insurance_company.social_reason)+" ")]):_vm._e(),_c('small',{staticClass:"text-muted"},[_vm._v(_vm._s(insurance.situation))])]),_c('div',[_c('table',{staticClass:"mb-3"},[_c('tr',[_c('td',[_c('strong',[_vm._v("Apólice:")])]),_c('td',{staticClass:"pl-2"},[_vm._v(_vm._s(_vm._f("date")(insurance.validity)))])]),_c('tr',[_c('td',[_c('strong',[_vm._v("Cobertura:")])]),_c('td',{staticClass:"pl-2"},[_vm._v(_vm._s(_vm._f("money")(insurance.coverage)))])])])])])}),1),_c('div',{staticClass:"d-flex justify-content-end w-100 mt-3"},[_c('b-button',{attrs:{"variant":"primary","disabled":_vm.selecteds && _vm.selecteds.length === 0},on:{"click":function($event){return _vm.sendSelecteds()}}},[_vm._v(" Salvar seleção ")])],1)],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }